/* General Body Styles */
body {
    font-family: 'Roboto', sans-serif;
    background-color: #f7f7f7; /* Soft light gray background */
    margin: 0;
    padding: 0;
  }
  
  /* Header */
  .jobportal h1 {
    text-align: center;
    color: #f1710f;
    text-shadow:3px 1px 1px rgb(255, 255, 255);
    padding: 30px 0;
    font-size: 2.5em;
    margin: 0;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
  }
  
  /* Container for Cards */
  .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 30px;
    padding: 20px;
    max-width: 1200px;
    margin: auto;
  }
  
  /* Card Styles */
  .card {
    background-color: #fff; /* White background for the card */
    border-radius: 15px;
    padding: 25px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    overflow: hidden;
    position: relative;
  }
  
  /* Hover Effects */
  .card:hover {
    transform: translateY(-10px); /* Card lifts slightly */
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
  }
  
  /* Job Title Styles */
  .jobtitle {
    font-size: 1.8em;
    color: #f1710f;
    font-weight: bold;
    margin-bottom: 15px;
    text-transform: capitalize;
  }
  
  /* Skills List (Badges) */
  .card ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .card ul li {
    background-color: #1780e8e7; /* Blue background */
    color: #fff;
    padding: 8px 18px;
    border-radius: 25px;
    font-size: 0.95em;
    font-weight: 500;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
  }
  
  .card ul li:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  /* Apply Button Section */
  .apply-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .apply-button p {
    font-size: 1.1em;
    color: #000000; /* Light gray color for the salary */
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  .apply-button a {
    background-color: #28a745; /* Green button for apply */
    color: #fff;
    padding: 10px 25px;
    font-size: 1.1em;
    font-weight: 600;
    border-radius: 30px;
    border: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
  }
  
  .apply-button a:hover {
    background-color: #218838; /* Darker green on hover */
    transform: scale(1.05); /* Button grows slightly on hover */
    text-decoration: none;
    color: #fff;

  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .container {
      grid-template-columns: 1fr; /* Stack cards vertically on smaller screens */
      gap: 20px;
    }
  
    .card {
      padding: 20px;
    }
  
    .jobtitle {
      font-size: 1.6em;
    }
  
    .apply-button p {
      font-size: 1em;
    }
  }
  