.application-form-container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #ffffff; /* White background */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #333; /* Dark text for contrast */
}

.application-form-container h1 {
    text-align: center;
    color: #ff7300; /* Orange title */
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
}

.application-form .form-group {
    margin-bottom: 1.5rem;
}

.application-form .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #333; /* Dark text for labels */
}

.application-form .form-group input,
.application-form .form-group select {
    width: 100%;
    padding: 0.6rem; /* Reduced padding for input fields */
    font-size: 0.9rem; /* Slightly smaller font size */
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    background-color: #fff;
    color: #333;
}

.application-form .form-group input:focus,
.application-form .form-group select:focus {
    border-color: #ff7300; /* Orange border on focus */
    box-shadow: 0 0 3px rgba(255, 115, 0, 0.3);
}
.form-group textarea{
    width: 100%;
    padding: 0.6rem; /* Reduced padding for input fields */
    font-size: 0.9rem; /* Slightly smaller font size */
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    background-color: #fff;
    color: #333;
}
.skills-input-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.skills-input-container input {
    flex: 1;
    padding: 0.6rem; /* Reduced padding for skills input */
    font-size: 0.9rem; /* Adjust font size */
}

.add-skill-btn {
    padding: 0.6rem 1rem; /* Reduced padding */
    font-size: 0.85rem;
    color: #fff;
    background-color: #ff7300; /* Orange button */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.add-skill-btn:hover {
    background-color: #e66500; /* Darker orange on hover */
}

.skills-tags {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.skill-tag {
    background-color: #ff7300; /* Orange skill tag */
    color: #fff;
    padding: 0.4rem 0.7rem; /* Reduced padding */
    border-radius: 20px;
    font-size: 0.85rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.remove-skill-btn {
    background: none;
    border: none;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
}

.remove-skill-btn:hover {
    color: #ffb399; /* Lighter orange for hover */
}

.submit-btn {
    display: block;
    width: 100%;
    padding: 0.8rem;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #ff7300; /* Orange submit button */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-btn:disabled {
    cursor: not-allowed;
}

.submit-btn:hover {
    background-color: #e66500; /* Darker orange on hover */
}

.file-preview p {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    color: #333;
}

.file-preview a {
    color: #ff7300; /* Orange link for file preview */
    text-decoration: underline;
    font-size: 0.9rem;
}

.file-preview a:hover {
    color: #e66500; /* Darker orange on hover */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .application-form-container {
        padding: 1.5rem; /* Reduce padding for smaller screens */
    }

    .application-form-container h1 {
        font-size: 1.5rem; /* Adjust title size */
    }

    .application-form .form-group input,
    .application-form .form-group select {
        padding: 0.5rem; /* Further reduced padding */
        font-size: 0.85rem; /* Smaller font size */
    }

    .skills-input-container input {
        padding: 0.5rem; /* Adjust padding for smaller screens */
        font-size: 0.85rem;
    }

    .add-skill-btn {
        padding: 0.5rem 0.8rem; /* Smaller button padding */
        font-size: 0.8rem; /* Adjust font size */
    }

    .skill-tag {
        padding: 0.3rem 0.6rem; /* Smaller tag padding */
        font-size: 0.8rem; /* Smaller font size */
    }

    .submit-btn {
        padding: 0.6rem; /* Adjust submit button padding */
        font-size: 0.9rem; /* Slightly smaller font size */
    }
}
