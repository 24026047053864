.my-applications-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    margin-bottom: 20px;
}

.loading {
    text-align: center;
    font-size: 18px;
    color: #555;
}

.error {
    text-align: center;
    color: red;
    font-weight: bold;
}

.applications-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.application-card {
    background: white;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.application-card h2 {
    margin: 0;
    font-size: 20px;
    color: #333;
}

.application-card p {
    margin: 5px 0;
}

.application-card a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.application-card a:hover {
    text-decoration: underline;
}
