/* General Styling */
.home {
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
    background: linear-gradient(to right, #ff3301c9, #f5f5f5);
    padding-bottom: 0rem;
}
.banner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    background-image: url('../assets/backer.jpg'); /* Path to your image */
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    perspective: 1200px;  
    animation: elegantMove 8s ease-out infinite; 
}



.banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.142); /* Semi-transparent black overlay */
    z-index: 1;
    pointer-events: none; /* Ensures the overlay doesn't block interactions */
}
@keyframes elegantMove {
    0% {
        background-position: center top;
        transform: scale(1);
        filter: brightness(100%);
    }
    25% {
        background-position: center 25%;
        transform: scale(1.02);
        filter: brightness(105%);
    }
    50% {
        background-position: center bottom;
        transform: scale(1.03);
        filter: brightness(110%);
    }
    75% {
        background-position: center 75%;
        transform: scale(1.02);
        filter: brightness(105%);
    }
    100% {
        background-position: center top;
        transform: scale(1);
        filter: brightness(100%);
    }
}
  
.banner-content {
    position: absolute;
    color: white;
    text-align: center;
    margin: 0 20px;
    margin-left: -400px;
    z-index: 2 !important;
    transform-style: preserve-3d;
}
  
.banner h1 {
    font-size: 4rem;
    text-align: left;
    font-weight: bolder;
    text-transform: uppercase;
    margin: 0;
    color: #fff;
    text-shadow: 0px 5px 0px  rgb(237, 254, 0);
}
  
.banner p {
    font-size: 1.4rem;
    margin-top: 10px;
    color: aliceblue;
}
  
.cta-button {
    margin-top: 20px;
    background-color: #f1710f;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
.cta-button a {
    color: white;
    text-decoration: none;
    font-size: 2rem;
}
  
.cta-button:hover {
    background-color: #e0600d;
}
  
/* Responsive Adjustments */
@media (max-width: 768px) {
    .banner-content {
        margin: 0 10px;
    }

    .banner h1 {
        font-size: 3rem;
        text-shadow: 0px 4px 0px  rgb(237, 254, 0);

    }

    .banner p {
        font-size: 0.9rem;
    }

    .cta-button {
        font-size: 0.9rem;
        padding: 5px 15px;
        border-radius: 20px;
    }
}

/* Stats Section */
.stats {
    text-align: center;
    padding: 2rem 1rem;
    background: #fff;
    margin-top: 2rem;
    border-radius: 10px;
}

.stats-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
}

.stat-item {
    width: 100%;
    max-width: 300px;
    padding: 1rem;
    border: 2px solid #e0600d;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .stat-item {
        width: 100%;
    }
}

/* Services Section */
.services {
    padding: 2rem 1rem;
    background: #f9f9f9;
}
.services h2 {
    text-align: center;
    margin-bottom: 2rem;
}

.services-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    margin-bottom: 30px;
}

.service-card {
    border: 2px solid #e0600d;
    border-radius: 10px;
    width: 100%;
    max-width: 300px;
    padding: 10px 1rem;
;
}

@media (max-width: 768px) {
    .services-grid {
        flex-direction: column;
        align-items: center;
    }
}

/* Testimonials Section */
.testimonials {
    padding: 2rem 1rem;
    text-align: center;
    margin-top: 2rem;
    background: #f9f9f9;
    font-size: 2rem;
    margin-bottom: 2rem;
}

@media (max-width: 768px) {
    .testimonials {
        padding: 1rem;
    }

    .testimonial {
        font-size: 1rem;
    }
}

/* Contact Section */
.contact {
    padding: 2rem 1rem;
    text-align: center;
    background-color: #ffffff;
}

.contact .cta-button {
    padding: 0.6rem 1.2;}
.social-icons-container{
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
}

@media (max-width: 768px) {
    .contact {
        padding: 1rem;
    }
}
